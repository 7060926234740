import { ceil } from "../lodash";
import {formatDate,getMonthValue} from "../vendor/datefns";
export const ImagePath='https://firebasestorage.googleapis.com/v0/b/flamelink-demo-ee23b.appspot.com/o/';
export const LOGIN_TIME_NAME = '__login_time__';
export const AUTH_TOKEN_NAME = '__token__';
export const AUTH_USER_ID = '__userid__';
export const AUTH_ACCOUNT_ID = '__accountid__';
export const AUTH_USER_NAME = '__username__';
export const AUTH_EMAIL_ADDRESS = '__emailAddress__';
export const AUTHORIZED_TOKEN = '__authToken__';
export const AUTH_DK_NUMBER = '__dkNumber__';
export const AUTH_AGENT_CODE = '__agentCode__';
export const AUTH_USER_TYPE = '__userType__';
export const USER_PICTURE = '__userPicture__';
export const REPORTING_DATA = '__reportingdata__';
export const USER_TRAMS_GROUP_ID='__userTramsGroupId__';
export const USER_PRPFILE_NO='__UserPrfileNo__';
export const CORPORATE_BOOKING_TOOL_URL='__corporateBookingTool__';
export const RETURN_URL='__returnUrl__';
export const USER_ASSOISATED_LIST ='__userAssoisatedList__';
export const USER_ITOKEN ='__itoken__';
export const REPORTING_PAIDUNPAIDDATA = '__reportingpaidunpaiddata__';
export const ALLOWEDUSER = ["asingh@web-fares.com","pshrivastava@web-fares.com"];
export const ISSITESTATUS='live';
export const WEBSITEURL='https://theatlas365.com/';
export const APIURL='https://gtltravelapi.azurewebsites.net';// For Live
export const JETBRIDGEURL='https://greaves.wfares.com';// For Live
export  const APIPROFILEURL='https://gtlprofileapi.azurewebsites.net'; // For Live
//export  const APIPAUREURL='https://testgtshoreexapp20211207.azurewebsites.net/api/Invoice/LiveUpload'; // For  Live
//export  const APIPAUREURL='https://livegreavesapi.azurewebsites.net/api/Invoice/LiveUpload'; // For  Live
export  const APIPAUREURL='https://livegreavesapi.azurewebsites.net/api/Invoice/v1/Upload'; // For  Live
export  const APIPUPLOADAUREURL='https://testgtshoreexapp20211207.azurewebsites.net/api/UploadFile/UploadFile'; // For  Live
export const STRIPE_KEY='pk_live_2OEC2YurzTXqQVRgZ7alL1Qy00AIN2rdYA';//Live  Key
//export  const APIOCRPURL='https://livegreavesapi.azurewebsites.net/api/Prod/OCR'; //For  Live
export  const APIOCRPURL='https://livegreavesapi.azurewebsites.net/api/PROD/OCR'; //For  Live

// export const ISSITESTATUS='test';
// export const WEBSITEURL='https://testtheatlas365.web.app/';
// export const APIURL='https://testtravelapi.azurewebsites.net';// For Sandbox
// export const JETBRIDGEURL='https://wfares-booking-tool.web.app';// For Sandbox
// export  const APIPROFILEURL='https://testprofileapi.azurewebsites.net'; // For  Sandbox
// export  const APIPAUREURL='https://testgtshoreexapp20211207.azurewebsites.net/api/Invoice/v1/Upload'; // For  Sandbox
// export  const APIPUPLOADAUREURL='https://testgtshoreexapp20211207.azurewebsites.net/api/UploadFile/UploadFile'; // For  Sandbox
// export const STRIPE_KEY='pk_test_4Ibkz248E6DzIMmbqLehBwRf00Cesdvbbf';//Test Key
// export  const APIOCRPURL='https://testgtshoreexapp20211207.azurewebsites.net/api/test/OCR'; //For  Test

export  const APISHOREESAPPURL='https://testgtshoreexapp20211207.azurewebsites.net'; //For  Sandbox

export const APPLICATIONDOMAIN=0;
export const INSTGRAMACCESSTOKEN='IGQVJXekZA5N2xqMk41dktJOTdOU3RDdkZAuVGgxTDZARSG5OT0NkYlpHWEhrZAkcyWU5NcXlZAdUUydTZAGRWxDY0JPWjFLQlA2NUlKR05HMV9MV2FKY3NSWVZAqdzJxbUItaWdEVGNfOGJYV09vYkZAEbDFLVAZDZD';
export const MonthData=[{'val':1,'name':'Jan'},
{'val':2,'name':'Feb'},
{'val':3,'name':'Mar'},
{'val':4,'name':'Apr'},
{'val':5,'name':'May'},
{'val':6,'name':'Jun'},
{'val':7,'name':'Jul'},
{'val':8,'name':'Aug'},
{'val':9,'name':'Sep'},
{'val':10,'name':'Oct'},
{'val':11,'name':'Nov'},
{'val':12,'name':'Dec'}]


var CryptoJS = require("crypto-js");
const INCPKEY='520eab5710f04ee7';
//const INCPKEY='8080808080808080';
var KEYVAL = CryptoJS.enc.Utf8.parse(INCPKEY);
var IVVAL = CryptoJS.enc.Utf8.parse(INCPKEY);
export const ENYCRYPTVALUE=(e:any)=>
{
   return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(e), KEYVAL,  
{
keySize: 128 / 8,   
iv: IVVAL,  
mode: CryptoJS.mode.CBC,  
padding: CryptoJS.pad.Pkcs7 
}).toString(); 
}
export const DECRTIPTVALUE=(cipherText:any)=>  
{  
return CryptoJS.AES.decrypt(cipherText, KEYVAL,  
{
   keySize: 128 / 8,   
   iv: IVVAL,  
   mode: CryptoJS.mode.CBC,  
   padding: CryptoJS.pad.Pkcs7 
}).toString(CryptoJS.enc.Utf8);; 
}

export const creditCards = [
   { id: 'AX', name: 'American Express' },
   { id: 'VI', name: 'Visa' },
   { id: 'DS', name: 'Discover' },
];

export function getCreditCards(){
   return creditCards.concat({value: 'CA', name: 'Master Card'}) ; 
}
export function yearList(){
   let min = new Date().getFullYear();
   let years = [];
   for(let i = 0 ; i < 20 ; i ++ ){
       years.push(min + i);
   }

   return years;
}
export const COUNTRY_CODES = [
   { "name": "Aland Islands", "id": "AX" },
   { "name": "Afghanistan", "id": "AF" }, { "name": "Albania", "id": "AL" }, { "name": "Algeria", "id": "DZ" },
   { "name": "American Samoa", "id": "AS" }, { "name": "Andorra", "id": "AD" }, { "name": "Angola", "id": "AO" },
   { "name": "Anguilla", "id": "AI" }, { "name": "Antarctica", "id": "AQ" }, { "name": "Antigua and Barbuda", "id": "AG" },
   { "name": "Argentina", "id": "AR" }, { "name": "Armenia", "id": "AM" }, { "name": "Aruba", "id": "AW" },
   { "name": "Australia", "id": "AU" },
   { "name": "Austria", "id": "AT" }, { "name": "Azerbaijan", "id": "AZ" }, { "name": "Bahamas", "id": "BS" },
   { "name": "Bahrain", "id": "BH" },
   { "name": "Bangladesh", "id": "BD" }, { "name": "Barbados", "id": "BB" }, { "name": "Belarus", "id": "BY" },
   { "name": "Belgium", "id": "BE" }, { "name": "Belize", "id": "BZ" }, { "name": "Benin", "id": "BJ" },
   { "name": "Bermuda", "id": "BM" }, { "name": "Bhutan", "id": "BT" }, { "name": "Bolivia", "id": "BO" },
   { "name": "Bosnia and Herzegovina", "id": "BA" }, { "name": "Botswana", "id": "BW" }, { "name": "Bouvet Island", "id": "BV" },
   { "name": "Brazil", "id": "BR" }, { "name": "British Virgin Islands", "id": "VG" },
   { "name": "British Indian Ocean Territory", "id": "IO" }, { "name": "Brunei Darussalam", "id": "BN" },
   { "name": "Bulgaria", "id": "BG" }, { "name": "Burkina Faso", "id": "BF" }, { "name": "Burundi", "id": "BI" },
   { "name": "Cambodia", "id": "KH" }, { "name": "Cameroon", "id": "CM" }, { "name": "Canada", "id": "CA" },
   { "name": "Cape Verde", "id": "CV" }, { "name": "Cayman Islands", "id": "KY" }, { "name": "Central African Republic", "id": "CF" },
   { "name": "Chad", "id": "TD" }, { "name": "Chile", "id": "CL" }, { "name": "China", "id": "CN" },
   { "name": "Hong Kong", "id": " SAR China" }, { "name": "Macao", "id": " SAR China" }, { "name": "Christmas Island", "id": "CX" },
   { "name": "Cocos (Keeling) Islands", "id": "CC" }, { "name": "Colombia", "id": "CO" }, { "name": "Comoros", "id": "KM" },
   { "name": "Congo (Brazzaville)", "id": "CG" }, { "name": "Congo", "id": " (Kinshasa)" }, { "name": "Cook Islands", "id": "CK" },
   { "name": "Costa Rica", "id": "CR" }, { "name": "Côte d'Ivoire", "id": "CI" }, { "name": "Croatia", "id": "HR" },
   { "name": "Cuba", "id": "CU" }, { "name": "Cyprus", "id": "CY" }, { "name": "Czech Republic", "id": "CZ" },
   { "name": "Denmark", "id": "DK" }, { "name": "Djibouti", "id": "DJ" }, { "name": "Dominica", "id": "DM" },
   { "name": "Dominican Republic", "id": "DO" }, { "name": "Ecuador", "id": "EC" }, { "name": "Egypt", "id": "EG" },
   { "name": "El Salvador", "id": "SV" }, { "name": "Equatorial Guinea", "id": "GQ" }, { "name": "Eritrea", "id": "ER" },
   { "name": "Estonia", "id": "EE" }, { "name": "Ethiopia", "id": "ET" }, { "name": "Falkland Islands (Malvinas)", "id": "FK" },
   { "name": "Faroe Islands", "id": "FO" }, { "name": "Fiji", "id": "FJ" }, { "name": "Finland", "id": "FI" },
   { "name": "France", "id": "FR" }, { "name": "French Guiana", "id": "GF" }, { "name": "French Polynesia", "id": "PF" },
   { "name": "French Southern Territories", "id": "TF" }, { "name": "Gabon", "id": "GA" }, { "name": "Gambia", "id": "GM" },
   { "name": "Georgia", "id": "GE" }, { "name": "Germany", "id": "DE" }, { "name": "Ghana", "id": "GH" },
   { "name": "Gibraltar", "id": "GI" }, { "name": "Greece", "id": "GR" }, { "name": "Greenland", "id": "GL" },
   { "name": "Grenada", "id": "GD" }, { "name": "Guadeloupe", "id": "GP" }, { "name": "Guam", "id": "GU" },
   { "name": "Guatemala", "id": "GT" }, { "name": "Guernsey", "id": "GG" }, { "name": "Guinea", "id": "GN" },
   { "name": "Guinea-Bissau", "id": "GW" }, { "name": "Guyana", "id": "GY" }, { "name": "Haiti", "id": "HT" },
   { "name": "Heard and Mcdonald Islands", "id": "HM" }, { "name": "Holy See (Vatican City State)", "id": "VA" },
   { "name": "Honduras", "id": "HN" }, { "name": "Hungary", "id": "HU" }, { "name": "Iceland", "id": "IS" },
   { "name": "India", "id": "IN" }, { "name": "Indonesia", "id": "ID" }, { "name": "Iran", "id": " Islamic Republic of" },
   { "name": "Iraq", "id": "IQ" }, { "name": "Ireland", "id": "IE" }, { "name": "Isle of Man", "id": "IM" },
   { "name": "Israel", "id": "IL" }, { "name": "Italy", "id": "IT" }, { "name": "Jamaica", "id": "JM" },
   { "name": "Japan", "id": "JP" }, { "name": "Jersey", "id": "JE" }, { "name": "Jordan", "id": "JO" },
   { "name": "Kazakhstan", "id": "KZ" }, { "name": "Kenya", "id": "KE" }, { "name": "Kiribati", "id": "KI" },
   { "name": "Korea (North)", "id": "KP" }, { "name": "Korea (South)", "id": "KR" }, { "name": "Kuwait", "id": "KW" },
   { "name": "Kyrgyzstan", "id": "KG" }, { "name": "Lao PDR", "id": "LA" }, { "name": "Latvia", "id": "LV" },
   { "name": "Lebanon", "id": "LB" }, { "name": "Lesotho", "id": "LS" }, { "name": "Liberia", "id": "LR" },
   { "name": "Libya", "id": "LY" }, { "name": "Liechtenstein", "id": "LI" }, { "name": "Lithuania", "id": "LT" },
   { "name": "Luxembourg", "id": "LU" }, { "name": "Macedonia", "id": " Republic of" }, { "name": "Madagascar", "id": "MG" },
   { "name": "Malawi", "id": "MW" }, { "name": "Malaysia", "id": "MY" }, { "name": "Maldives", "id": "MV" },
   { "name": "Mali", "id": "ML" }, { "name": "Malta", "id": "MT" }, { "name": "Marshall Islands", "id": "MH" },
   { "name": "Martinique", "id": "MQ" }, { "name": "Mauritania", "id": "MR" }, { "name": "Mauritius", "id": "MU" },
   { "name": "Mayotte", "id": "YT" }, { "name": "Mexico", "id": "MX" }, { "name": "Micronesia", "id": " Federated States of" },
   { "name": "Moldova", "id": "MD" }, { "name": "Monaco", "id": "MC" }, { "name": "Mongolia", "id": "MN" },
   { "name": "Montenegro", "id": "ME" }, { "name": "Montserrat", "id": "MS" }, { "name": "Morocco", "id": "MA" },
   { "name": "Mozambique", "id": "MZ" }, { "name": "Myanmar", "id": "MM" }, { "name": "Namibia", "id": "NA" },
   { "name": "Nauru", "id": "NR" }, { "name": "Nepal", "id": "NP" }, { "name": "Netherlands", "id": "NL" },
   { "name": "Netherlands Antilles", "id": "AN" }, { "name": "New Caledonia", "id": "NC" }, { "name": "New Zealand", "id": "NZ" },
   { "name": "Nicaragua", "id": "NI" }, { "name": "Niger", "id": "NE" }, { "name": "Nigeria", "id": "NG" },
   { "name": "Niue", "id": "NU" }, { "name": "Norfolk Island", "id": "NF" }, { "name": "Northern Mariana Islands", "id": "MP" },
   { "name": "Norway", "id": "NO" }, { "name": "Oman", "id": "OM" }, { "name": "Pakistan", "id": "PK" },
   { "name": "Palau", "id": "PW" }, { "name": "Palestinian Territory", "id": "PS" }, { "name": "Panama", "id": "PA" },
   { "name": "Papua New Guinea", "id": "PG" }, { "name": "Paraguay", "id": "PY" }, { "name": "Peru", "id": "PE" },
   { "name": "Philippines", "id": "PH" }, { "name": "Pitcairn", "id": "PN" }, { "name": "Poland", "id": "PL" },
   { "name": "Portugal", "id": "PT" }, { "name": "Puerto Rico", "id": "PR" }, { "name": "Qatar", "id": "QA" },
   { "name": "Réunion", "id": "RE" }, { "name": "Romania", "id": "RO" }, { "name": "Russian Federation", "id": "RU" },
   { "name": "Rwanda", "id": "RW" }, { "name": "Saint-Barthélemy", "id": "BL" }, { "name": "Saint Helena", "id": "SH" },
   { "name": "Saint Kitts and Nevis", "id": "KN" }, { "name": "Saint Lucia", "id": "LC" },
   { "name": "Saint-Martin (French part)", "id": "MF" }, { "name": "Saint Pierre and Miquelon", "id": "PM" },
   { "name": "Saint Vincent and Grenadines", "id": "VC" }, { "name": "Samoa", "id": "WS" }, { "name": "San Marino", "id": "SM" },
   { "name": "Sao Tome and Principe", "id": "ST" }, { "name": "Saudi Arabia", "id": "SA" }, { "name": "Senegal", "id": "SN" },
   { "name": "Serbia", "id": "RS" }, { "name": "Seychelles", "id": "SC" }, { "name": "Sierra Leone", "id": "SL" },
   { "name": "Singapore", "id": "SG" }, { "name": "Slovakia", "id": "SK" }, { "name": "Slovenia", "id": "SI" },
   { "name": "Solomon Islands", "id": "SB" }, { "name": "Somalia", "id": "SO" }, { "name": "South Africa", "id": "ZA" },
   { "name": "South Georgia and the South Sandwich Islands", "id": "GS" }, { "name": "South Sudan", "id": "SS" },
   { "name": "Spain", "id": "ES" }, { "name": "Sri Lanka", "id": "LK" }, { "name": "Sudan", "id": "SD" },
   { "name": "Suriname", "id": "SR" }, { "name": "Svalbard and Jan Mayen Islands", "id": "SJ" },
   { "name": "Swaziland", "id": "SZ" }, { "name": "Sweden", "id": "SE" }, { "name": "Switzerland", "id": "CH" },
   { "name": "Syrian Arab Republic (Syria)", "id": "SY" }, { "name": "Taiwan", "id": " Republic of China" },
   { "name": "Tajikistan", "id": "TJ" }, { "name": "Tanzania", "id": " United Republic of" }, { "name": "Thailand", "id": "TH" },
   { "name": "Timor-Leste", "id": "TL" }, { "name": "Togo", "id": "TG" }, { "name": "Tokelau", "id": "TK" },
   { "name": "Tonga", "id": "TO" }, { "name": "Trinidad and Tobago", "id": "TT" }, { "name": "Tunisia", "id": "TN" },
   { "name": "Turkey", "id": "TR" }, { "name": "Turkmenistan", "id": "TM" }, { "name": "Turks and Caicos Islands", "id": "TC" },
   { "name": "Tuvalu", "id": "TV" }, { "name": "Uganda", "id": "UG" }, { "name": "Ukraine", "id": "UA" },
   { "name": "United Arab Emirates", "id": "AE" }, { "name": "United Kingdom", "id": "GB" },
   { "name": "United States of America", "id": "US" }, { "name": "US Minor Outlying Islands", "id": "UM" },
   { "name": "Uruguay", "id": "UY" }, { "name": "Uzbekistan", "id": "UZ" }, { "name": "Vanuatu", "id": "VU" },
   { "name": "Venezuela (Bolivarian Republic)", "id": "VE" }, { "name": "Viet Nam", "id": "VN" },
   { "name": "Virgin Islands", "id": " US" }, { "name": "Wallis and Futuna Islands", "id": "WF" },
   { "name": "Western Sahara", "id": "EH" }, { "name": "Yemen", "id": "YE" }, { "name": "Zambia", "id": "ZM" },
   { "name": "Zimbabwe", "id": "ZW" }
];


export const EVENTTYPELIST=[{'value':'airBookings','name':'Air Bookings'},
{'value':'hotelBookings','name':'Hotel Bookings'},
{'value':'cruiseBookings','name':'Cruise Bookings'},
 {'value':'carBookings','name':'Car Bookings'},
  {'value':'railBookings','name':'Rail Bookings'},
{'value':'activities','name':'Activities'},
{'value':'arrangements','name':'Arrangements'}
]
export const SATEMENTEMAIL=['awaggoner@travelleaders365.com','cpascua@travelleaders365.com'];
export const RESRTICDKUMBER=['na','tt11145'];
 export function getUrlString(str){
   return  str.toLowerCase().trim().replace(/[^\w\s]/gi, '-').replace(/\s+/g, '-'); 
}
export function showPostDate(str){
   let dateval=new Date();
   let currentTime= dateval.getTime()/1000;
   let timediff= currentTime - str;
   let showdata='';
   if(timediff>24*3600)
   {
      let dateval=new Date(str*1000);
      showdata= getMonthValue(dateval)+' '+formatDate(dateval,'MM dd, yyyy')
   }
   else if(timediff>3600)
   {
     
      showdata='Last updated '+ceil(timediff/3600)+' hrs ago'
   }
   else if(timediff>60)
   {
     
      showdata='Last updated'+ceil(timediff/3600)+' min ago'
   }
   else
   {
     
      showdata='Last updated'+timediff+' sec ago'
   }
   return  showdata
}

/**
 * Aiirlines Codes List
 */
export const AIRLINE_CODES = [
   { "name": "Adria Airways", "id": "JP" },
   { "name": "Aegean Airlines", "id": "A3" },
   { "name": "Aer Lingus", "id": "EI" },
   { "name": "P5", "id": "P5" },
   { "name": "Aeroflot", "id": "SU" },
   { "name": "Aerolineas Argentinas", "id": "AR" },
   { "name": "Aerolineas Galapagos S.A. Aerogal", "id": "2K" },
   { "name": "Aeromexico", "id": "AM" },
   { "name": "Africa World Airlines", "id": "AW" },
   { "name": "Aigle Azur", "id": "ZI" },
   { "name": "AH", "id": "AH" },
   { "name": "Air Arabia", "id": "G9" },
   { "name": "Air Astana", "id": "KC" },
   { "name": "Air Austral", "id": "UU" },
   { "name": "Air Baltic", "id": "BT" },
   { "name": "Air Berlin", "id": "AB" },
   { "name": "Air Botswana", "id": "BP" },
   { "name": "Air Burkina", "id": "2J" },
   { "name": "Air Cairo", "id": "SM" },
   { "name": "Air Caledonie", "id": "TY" },
   { "name": "Air Canada", "id": "AC" },
   { "name": "Air Caraibes", "id": "TX" },
   { "name": "Air China", "id": "CA" },
   { "name": "Air Corsica", "id": "XK" },
   { "name": "Air Europa", "id": "UX" },
   { "name": "Air France", "id": "AF" },
   { "name": "Air India", "id": "AI" },
   { "name": "Air Koryo", "id": "JS" },
   { "name": "Air Macau", "id": "NX" },
   { "name": "Air Madagascar", "id": "MD" },
   { "name": "Air Malta", "id": "KM" },
   { "name": "Air Mauritius", "id": "MK" },
   { "name": "Air Moldova", "id": "9U" },
   { "name": "Air Namibia", "id": "SW" },
   { "name": "Air New Zealand", "id": "NZ" },
   { "name": "Air Niugini", "id": "PX" },
   { "name": "Air Nostrum", "id": "YW" }, { "name": "Air Peace", "id": "P4" },
   { "name": "Air SERBIA a.d. Beograd", "id": "JU" }, { "name": "Air Seychelles", "id": "HM" },
   { "name": "Air Tahiti", "id": "VT" }, { "name": "Air Tahiti Nui", "id": "TN" },
   { "name": "Air Transat", "id": "TS" }, { "name": "Air Vanuatu", "id": "NF" },
   { "name": "AirBridgeCargo Airlines", "id": "RU" }, { "name": "Aircalin", "id": "SB" },
   { "name": "Airlink", "id": "4Z" }, { "name": "Alaska Airlines", "id": "AS" },
   { "name": "Alitalia", "id": "AZ" }, { "name": "All Nippon Airways", "id": "NH" },
   { "name": "Allied Air", "id": "4W" }, { "name": "AlMasria Universal Airlines", "id": "UJ" },
   { "name": "American Airlines", "id": "AA" }, { "name": "Arik Air", "id": "W3" },
   { "name": "Arkia Israeli Airlines", "id": "IZ" }, { "name": "Asiana", "id": "OZ" },
   { "name": "Atlas Air", "id": "5Y" }, { "name": "AtlasGlobal", "id": "KK" },
   { "name": "Austral", "id": "AU" }, { "name": "Austrian", "id": "OS" },
   { "name": "Avianca", "id": "AV" }, { "name": "Avianca Brasil", "id": "O6" },
   { "name": "Azerbaijan Airlines", "id": "J2" },
   { "name": "Azul Brazilian Airlines", "id": "AD" },
   { "name": "Bahamasair", "id": "UP" },
   { "name": "Bangkok Air", "id": "PG" },
   { "name": "Belavia - Belarusian Airlines", "id": "B2" },
   { "name": "BH AIR", "id": "8H" },
   { "name": "Biman", "id": "BG" },
   { "name": "Binter Canarias", "id": "NT" },
   { "name": "Blue Air", "id": "0B" },
   { "name": "Blue Panorama", "id": "BV" },
   { "name": "bmi Regional", "id": "BM" },
   { "name": "OB", "id": "OB" },
   { "name": "Braathens Regional Aviation AB", "id": "TF" },
   { "name": "British Airways", "id": "BA" },
   { "name": "Brussels Airlines", "id": "SN" },
   { "name": "Bulgaria air", "id": "FB" },
   { "name": "C.A.L. Cargo Airlines", "id": "5C" },
   { "name": "Camair-Co", "id": "QC" },
   { "name": "Cambodia Angkor Air", "id": "K6" },
   { "name": "Capital Airlines", "id": "JD" },
   { "name": "Cargojet Airways", "id": "W8" },
   { "name": "Cargolux S.A.", "id": "CV" },
   { "name": "Caribbean Airlines", "id": "BW" },
   { "name": "Carpatair", "id": "V3" },
   { "name": "Cathay Dragon", "id": "KA" },
   { "name": "Cathay Pacific", "id": "CX" },
   { "name": "Cayman Airways", "id": "KX" },
   { "name": "Cemair", "id": "5Z" },
   { "name": "China Airlines", "id": "CI" },
   { "name": "China Cargo Airlines", "id": "CK" },
   { "name": "China Eastern", "id": "MU" },
   { "name": "China Express Airlines", "id": "G5" },
   { "name": "China Postal Airlines", "id": "CF" },
   { "name": "China Southern Airlines", "id": "CZ" },
   { "name": "CityJet", "id": "WX" }, { "name": "Comair", "id": "MN" },
   { "name": "Condor", "id": "DE" }, { "name": "COPA Airlines", "id": "CM" },
   { "name": "Corendon Airlines", "id": "XC" },
   { "name": "Corsair International", "id": "SS" },
   { "name": "Croatia Airlines", "id": "OU" },
   { "name": "Cubana", "id": "CU" },
   { "name": "Czech Airlines", "id": "OK" },
   { "name": "Dana Air", "id": "9J" },
   { "name": "Delta Air Lines", "id": "DL" },
   { "name": "DHL Air", "id": "D0" },
   { "name": "DHL Aviation EEMEA B.S.C.(c)", "id": "ES*" },
   { "name": "Eastar Jet", "id": "ZE" },
   { "name": "Egyptair", "id": "MS" },
   { "name": "EL AL", "id": "LY" },
   { "name": "Emirates", "id": "EK" },
   { "name": "Ethiopian Airlines", "id": "ET" },
   { "name": "Etihad Airways", "id": "EY" },
   { "name": "Euroatlantic Airways", "id": "YU" },
   { "name": "European Air Transport", "id": "QY" },
   { "name": "Eurowings", "id": "EW" },
   { "name": "EVA Air", "id": "BR" },
   { "name": "Evelop Airlines", "id": "E9" },
   { "name": "Federal Express", "id": "FX" },
   { "name": "Fiji Airways", "id": "FJ" },
   { "name": "Finnair", "id": "AY" },
   { "name": "flybe", "id": "BE" },
   { "name": "flydubai", "id": "FZ" },
   { "name": "FlyEgypt", "id": "FT" },
   { "name": "Freebird Airlines", "id": "FH" },
   { "name": "Garuda", "id": "GA" },
   { "name": "Georgian Airways", "id": "A9" },
   { "name": "Germania", "id": "ST" }, { "name": "G3", "id": "G3" },
   { "name": "Guangxi Beibu Gulf Airlines", "id": "GX" },
   { "name": "Gulf Air", "id": "GF" }, { "name": "Hahn Air", "id": "HR*" },
   { "name": "Hainan Airlines", "id": "HU" }, { "name": "Hawaiian Airlines", "id": "HA" },
   { "name": "Hi Fly", "id": "5K" }, { "name": "Hong Kong Airlines", "id": "HX" },
   { "name": "Hong Kong Express Airways", "id": "UO" }, { "name": "IBERIA", "id": "IB" }, { "name": "Icelandair", "id": "FI" },
   { "name": "InselAir", "id": "7i" }, { "name": "Interjet", "id": "4O" }, { "name": "Iran Air", "id": "IR" },
   { "name": "Iran Airtour Airline", "id": "B9" }, { "name": "Iran Aseman Airlines", "id": "EP" }, { "name": "Israir", "id": "6H" },
   { "name": "Japan Airlines", "id": "JL" }, { "name": "Jazeera Airways", "id": "J9" }, { "name": "Jet Airways", "id": "9W" },
   { "name": "Jet Lite (India)", "id": "S2" }, { "name": "JetBlue", "id": "B6" }, { "name": "Jordan Aviation", "id": "R5" },
   { "name": "JSC Nordavia-RA", "id": "5N" }, { "name": "Juneyao Airlines", "id": "HO" }, { "name": "Kenya Airways", "id": "KQ" },
   { "name": "Kish Air", "id": "Y9" }, { "name": "KLM", "id": "KL" }, { "name": "Korean Air", "id": "KE" }, { "name": "Kuwait Airways", "id": "KU" },
   { "name": "LACSA", "id": "LR" }, { "name": "LAM", "id": "TM" }, { "name": "Lao Airlines", "id": "QV" }, { "name": "LATAM Airlines Argentina", "id": "4M" },
   { "name": "LATAM Airlines Brasil", "id": "JJ" }, { "name": "LATAM Airlines Colombia", "id": "4C" }, { "name": "LATAM Airlines Ecuador", "id": "XL" },
   { "name": "LATAM Airlines Group", "id": "LA" }, { "name": "LATAM Airlines Paraguay", "id": "PZ" }, { "name": "LATAM Airlines Peru", "id": "LP" },
   { "name": "LATAM Cargo Brasil", "id": "M3" }, { "name": "LATAM Cargo Chile", "id": "UC" }, { "name": "LATAM Cargo Mexico", "id": "M7" },
   { "name": "LIAT Airlines", "id": "LI" }, { "name": "LLC ", "id": "N4" }, { "name": "Loong Air", "id": "GJ" }, { "name": "LOT Polish Airlines", "id": "LO" },
   { "name": "Lucky Air", "id": "8L" }, { "name": "Lufthansa", "id": "LH" }, { "name": "Lufthansa Cargo", "id": "LH" },
   { "name": "Lufthansa CityLine", "id": "CL" }, { "name": "Luxair", "id": "LG" }, { "name": "Mahan Air", "id": "W5" },
   { "name": "Malaysia Airlines", "id": "MH" }, { "name": "Malindo Air", "id": "OD" }, { "name": "Mandarin Airlines", "id": "AE" },
   { "name": "Martinair Cargo", "id": "MP" }, { "name": "Mauritania Airlines International", "id": "L6" }, { "name": "MEA", "id": "ME" },
   { "name": "Meridiana fly", "id": "IG" }, { "name": "MIAT", "id": "OM" }, { "name": "Mistral Air", "id": "M4" }, { "name": "MNG Airlines", "id": "MB" },
   { "name": "Montenegro Airlines", "id": "YM" }, { "name": "Myanmar Airways International", "id": "8M" }, { "name": "Neos", "id": "NO" },
   { "name": "Nesma Airlines", "id": "NE" }, { "name": "Nextjet", "id": "2N" }, { "name": "NIKI", "id": "HG" }, { "name": "Nile Air", "id": "NP" },
   { "name": "Nippon Cargo Airlines (NCA)", "id": "KZ" }, { "name": "Nouvelair", "id": "BJ" }, { "name": "Okay Airways", "id": "BK" },
   { "name": "Olympic Air", "id": "OA" }, { "name": "Oman Air", "id": "WY" }, { "name": "Onur Air", "id": "8Q" }, { "name": "Overland Airways", "id": "OF" },
   { "name": "Pegas Fly", "id": "IK" }, { "name": "Pegasus Airlines", "id": "PC" }, { "name": "", "id": "NI" }, { "name": "Philippine Airlines", "id": "PR" },
   { "name": "PIA", "id": "PK" }, { "name": "Precision Air", "id": "PW" }, { "name": "PrivatAir", "id": "PV" }, { "name": "Qantas", "id": "QF" },
   { "name": "Qatar Airways", "id": "QR" }, { "name": "Rossiya Airlines", "id": "FV" }, { "name": "Royal Air Maroc", "id": "AT" },
   { "name": "Royal Brunei", "id": "BI" }, { "name": "Royal Jordanian", "id": "RJ" }, { "name": "RwandAir", "id": "WB" },
   { "name": "S7 Airlines", "id": "S7" }, { "name": "SAA", "id": "SA" }, { "name": "Safair", "id": "FA*" }, { "name": "Safi Airways", "id": "4Q" },
   { "name": "Santa Barbara", "id": "S3" }, { "name": "SAS", "id": "SK" }, { "name": "", "id": "SP" }, { "name": "SATA Internacional", "id": "S4" },
   { "name": "Saudi Arabian Airlines", "id": "SV" }, { "name": "SF Airlines", "id": "O3" }, { "name": "Shandong Airlines", "id": "SC" },
   { "name": "Shanghai Airlines", "id": "FM" }, { "name": "Shenzhen Airlines", "id": "ZH" }, { "name": "SIA", "id": "SQ" },
   { "name": "SIA Cargo", "id": "SQ" }, { "name": "Sichuan Airlines", "id": "3U" }, { "name": "Silk Way West Airlines", "id": "7L" },
   { "name": "Silkair", "id": "MI" }, { "name": "SKY Airline", "id": "H2" }, { "name": "Somon Air", "id": "SZ" },
   { "name": "South African Express Airways", "id": "XZ" }, { "name": "SriLankan", "id": "UL" }, { "name": "SunExpress", "id": "XQ*" },
   { "name": "Suparna Airlines", "id": "Y8" }, { "name": "Surinam Airways", "id": "PY" }, { "name": "SWISS", "id": "LX" },
   { "name": "Syrianair", "id": "RB" }, { "name": "TAAG - Angola Airlines", "id": "DT" }, { "name": "TACA", "id": "TA" },
   { "name": "TACA Peru", "id": "T0" }, { "name": "TACV Cabo Verde Airlines", "id": "VR" }, { "name": "EQ", "id": "EQ" }, { "name": "TAP Portugal", "id": "TP" },
   { "name": "TAROM", "id": "RO" }, { "name": "Tassili Airlines", "id": "SF" }, { "name": "Thai Airways International", "id": "TG" },
   { "name": "Thai Lion Air", "id": "SL" }, { "name": "Turkish Airlines", "id": "TK" }, { "name": "Tianjin Airlines", "id": "GS" },
   { "name": "TUIfly", "id": "X3*" }, { "name": "Tunisair", "id": "TU" }, { "name": "T'way Air", "id": "TW" },
   { "name": "Ukraine International Airlines", "id": "PS" }, { "name": "United Airlines", "id": "UA" }, { "name": "UPS Airlines", "id": "5X" },
   { "name": "Ural Airlines", "id": "U6" }, { "name": "UTair", "id": "UT" }, { "name": "Uzbekistan Airways", "id": "HY" },
   { "name": "Vietjet", "id": "VJ" }, { "name": "Vietnam Airlines", "id": "VN" }, { "name": "VIM Airlines", "id": "NN" },
   { "name": "Virgin Atlantic", "id": "VS" }, { "name": "Virgin Australia", "id": "VA" }, { "name": "Volaris", "id": "Y4*" },
   { "name": "Vueling", "id": "VY" }, { "name": "Wamos Air", "id": "EB" }, { "name": "WestJet", "id": "WS" }, { "name": "White coloured by you", "id": "WI" },
   { "name": "Wideroe", "id": "WF" }, { "name": "Xiamen Airlines", "id": "MF" },
   { "name": "Spirit", "id": "NK" }, { "name": "Frontier", "id": "F9" }, { "name": "Southwest", "id": "WN" }, { "name": "Air Vistara", "id": "UK" }
];